import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

class Testimonials extends Component {
  render() {

    if(this.props.data){
      var testimonials = this.props.data.testimonials.map(function(testimonials){
        return <div key={testimonials.user}>
         <div className="myCarousel">
           <h3 className="heading">{testimonials.user}</h3>
           <h4 className="heading">{testimonials.role}</h4>
           <p className="testimonialsText">{testimonials.text}</p>
         </div>
       </div>
      })
    }

    return (
      <section id="testimonials">
         <div className="row">

            <div className="header-col">
               <h1 className="txt">LinkedIn Recommendations</h1>
            </div>

            <div className="flex-container">
               <div>
               <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
      >
         {testimonials}
      </Carousel>
               </div>
               </div>
            </div>
   </section>
    );
  }
}

export default Testimonials;
