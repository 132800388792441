import React, { Component } from 'react';
import ReactGA from 'react-ga';
import $ from 'jquery';
import './App.css';
import Header from './Components/Home/Header';
import Footer from './Components/Home/Footer';
import About from './Components/About';
import Resume from './Components/Resume';
import Contact from './Components/Contact';
import Testimonials from './Components/Testimonials';
import Portfolio from './Components/Portfolio';

class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      foo: 'bar',
      loading: true,
      error: false,
      resumeData: {}
    };
    ReactGA.initialize('UA-110570651-1');
    ReactGA.pageview(window.location.pathname);

  }

  getResumeData = (event) =>{
    $.ajax({
      url:'https://2hvxst57m4.execute-api.us-east-1.amazonaws.com/LIVE/resumelive',
      dataType:'json',
      cache: false,
      success: function(data){
        this.setState({resumeData: data.Item});
        setTimeout(function() { //Start the timer
          this.setState({loading : false});
        }.bind(this), 6000);
      }.bind(this),
      error: function(xhr, status, err){
        this.setState({
          loading : false,
          error : true
        });
      }.bind(this)
    });
  }


  componentDidMount(){
    this.getResumeData(this.event);
  }

  render() {
    const { loading, error } = this.state;
    if(loading) { // if your component doesn't have to wait for an async action, remove this block 
      return (
      <div className="parent">
      <div className="app-loading">
      <div className="logo"><img src="images/logo.png" alt="logo" width="40px" height="40px" /> </div>
      <svg className="spinner" viewBox="25 25 50 50">
        <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
      </svg>
      </div>
      <div className="box">
          <span className="imageLoader" id="one">
            <img src="../images/loader/1.png" id="spinx" className="imx" width="60px" height="60px" alt="React"/>
          </span>
          <span className="imageLoader" id="two">
            <img src="../images/loader/2.png" className="imx" width="60px" height="60px"  alt="React"/>
          </span>
          <span className="imageLoader" id="three">
            <img src="../images/loader/3.png" className="imx" width="60px" height="60px"  alt="React"/>
          </span>
          <span className="imageLoader" id="four">
            <img src="../images/loader/4.png" className="imx" width="60px" height="60px"  alt="React"/>
          </span>
          <span className="imageLoader" id="five">
            <img src="../images/loader/5.png" className="imx" width="60px" height="60px" alt="React"/>
          </span>
          <span className="imageLoader" id="six">
            <img src="../images/loader/6.png" className="imx1" width="50px" height="50px" alt="React"/>
          </span>
      </div>
      <div id="loadx">Powered By AWS :: Connecting to AWS Server ...</div>
      </div>
      ); // render null when app is not ready
    } else if(error){
      return(
        <div className="app-loading">500 Something went wrong</div>
      );
    }else{


    return (
      <div className="App">
        <Header data={this.state.resumeData.main}/>
        <About data={this.state.resumeData.main}/>
        <Resume data={this.state.resumeData.resume}/>
        <Portfolio data={this.state.resumeData.portfolio}/>
        <Testimonials data={this.state.resumeData.testimonials}/>
        <Contact data={this.state.resumeData.main}/>
        <Footer data={this.state.resumeData.main}/>
      </div>
    );
    }
  }
}

export default App;
