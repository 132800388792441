import React, { Component } from 'react';

class Resume extends Component {
  render() {

    if(this.props.data){
      var education = this.props.data.education.map(function(education){
        return <div key={education.school}><h3>{education.school}</h3>
        <p className="info">{education.degree} <span>&bull;</span><em className="date">{education.graduated}</em></p>
        <p className="info">{education.description}</p></div>
      })
      var work = this.props.data.work.map(function(work){
        var details = work.responsibilities.split("#");
        const listItems = details.map((string) =>
         <li key={string}>{string}</li>
        );
        return <div key={work.company}><h3>{work.company}</h3>
            <p className="info">{work.title}<span>&bull;</span> <em className="date">{work.years}</em></p>
            <p> {work.description}</p>
            <details><summary className="details">Details</summary><ul>{listItems}</ul></details>
        </div>
      })
      var t=0;
      var skills = this.props.data.skills.map(function(skills){

        return <div className = "col-md-6" key={skills.type}>
        <dl key={t++}>
        <dt>{skills.type}</dt>
        <dd>{skills.name}</dd>
        </dl>
        </div>
        })
      var certifications = this.props.data.certifications.map(function(certifications){

        return <dl key={t++}> 
        <dt>{certifications.authority}</dt>
        <dd><a href={certifications.urls} target="_blank" id="cert">{certifications.certificatenames}</a></dd>
        </dl>
        })
    }

    return (
      <section id="resume">

      <div className="row education">
         <div className="three columns header-col">
            <h1><span>Education</span></h1>
         </div>

         <div className="nine columns main-col">
            <div className="row item">
               <div className="twelve columns">
                 {education}
               </div>
            </div>
         </div>
      </div>


      <div className="row work">

         <div className="three columns header-col">
            <h1><span>Work</span></h1>
         </div>

         <div className="nine columns main-col">
          {work}
        </div>
    </div>



      <div className="row skill">

         <div className="three columns header-col">
            <h1><span>Skills</span></h1>
         </div>

         <div className="nine columns main-col">
				   <span className="skills">
					  {skills}
					</span>
			</div>
      </div>

      <div className="row">

<div className="three columns header-col">
   <h1><span>Certifications</span></h1>
</div>

<div className="nine columns main-col">
  <span className="skills">
   {certifications}
 </span>
</div>
</div>
   </section>
    );
  }
}

export default Resume;
