import React, { Component }  from 'react';
import Typewriter from "typewriter-effect";
  
class Ty extends Component {

 render() {
    let split = null;
    if(this.props.data){
      split = this.props.data.split(",");
    }
  return (
    <div >
      <Typewriter
        options = {{
            strings : split,
            autoStart: true,
            loop:true,
            deleteSpeed : 100
          }
        }
       />
    </div>
  );
}
}
export default Ty;