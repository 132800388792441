import React, { Component } from 'react';

class Contact extends Component {

   constructor(props){
      super(props);
      this.state = {
         contactName : '',
         contactEmail : '',
         contactSubject : '',
         contactMessage : '',
         btnvalue : false
      };
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
   }


   handleChange = (event) => {
      this.setState({[event.target.name]: event.target.value});
   }

   handleSubmit = (event) => {
      const loader = document.querySelector("#image-loader");
      const success = document.querySelector("#message-success");
      const warning = document.querySelector("#message-warning");
      const btn = document.querySelector("#submitbtn");

      this.displayLoading();
      
     const requestOptions = {
         method: 'POST',
         headers: { 'Content-Type': 'application/json' },
         mode: 'cors',
         body: JSON.stringify(this.state)
     };
     fetch('https://2hvxst57m4.execute-api.us-east-1.amazonaws.com/LIVE/resumelive', requestOptions)
         .then(function(response) {
            //console.log(response);
            loader.style.display ="none";
            success.style.display ="block";
            btn.style.display ="inline-block";
            return response.json();
          }).catch(function() {
            //console.log("error");
            loader.style.display ="none";
            warning.style.display ="block";
            btn.style.display ="inline-block";
          });
          event.preventDefault();
   }

   displayLoading() {
      const loader = document.querySelector("#image-loader");
      const btn = document.querySelector("#submitbtn");
      btn.style.display ="none";
      loader.style.display ="inline";
      btn.attributes.disabled=true;
   }


  render() {
    return (
      <section id="contact">

         <div className="row section-head">
               <h2 className="git">&nbsp; Get In Touch</h2>
               <form id="contactForm" onSubmit={this.handleSubmit}>
					<fieldset>

						   <label htmlFor="contactName">Name <span className="required">*</span></label>
						   <input type="text" defaultValue="" size="35" id="contactName" name="contactName" onChange={this.handleChange} required/>

						   <label htmlFor="contactEmail">Email <span className="required">*</span></label>
						   <input type="email" defaultValue="" pattern="([A-Za-z0-9][._]?)+[A-Za-z0-9]@[A-Za-z0-9]+(\.?[A-Za-z0-9]){2}\.(com?|net|org)+(\.[A-Za-z0-9]{2,4})?" title="Wrong email! Enter Correct Email" size="35" id="contactEmail" name="contactEmail" onChange={this.handleChange} required/>
                
						   <label htmlFor="contactSubject">Subject<span className="required">*</span></label>
						   <input type="text" defaultValue="" size="35" id="contactSubject" name="contactSubject" onChange={this.handleChange} required/>
                 
                     <label htmlFor="contactMessage">Message <span className="required">*</span></label>
                     <textarea value={this.state.value} onChange={this.handleChange} id="contactMessage" name="contactMessage" required/>
                  
                     <button id="submitbtn" className="submit" disabled={this.state.btnvalue}>Submit</button>
                     <span id="image-loader">
                        <img alt="" src="images/loader.gif" />
                     </span>

					</fieldset>
				   </form>

           <div id="message-warning"> Error! Something went Wrong! Please Try Again!!</div>
				   <div id="message-success">
                  <i className="fa fa-check"></i>Your message has been sent, thank you!<br />
				   </div>
           </div>

       </section>
    );
  }
}

export default Contact;
